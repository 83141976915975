import React from "react";
import { graphql } from "gatsby";
import CleanLayout from "../layouts/Clean";
import { Intro, SEO, PostPreview } from "components";
import { getImage } from "gatsby-plugin-image";

const BlogAuthor = ({ data }) => {
  //   const posts = data?.allContentfulBlogPost?.nodes;
  const {
    allContentfulAuthor: {
      nodes: [author],
    },
    allContentfulBlogPost: { nodes: posts },
  } = data;

  if (!author || !posts) return null;

  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title= {`Articoli di ${author.name}`}
        description= {`Scopri gli articoli che ${author.name} ha scritto per Pillole, il blog di Trame Digitali. Web Agency e startup innovativa`}
        meta={[{ 
          name: 'author',
          content: author.name,
        }]}
      />
      <div className="w-screen px-8 md:px-28">
        <Intro title={author.name} description={author.bio && author.bio.bio} />
        <section className="flex flex-col">
          {posts.map((post, index) => {
            const pImage = getImage(post?.image);
            return (
              <div className="my-8" key={index}>
                <PostPreview {...post} image={pImage} />
              </div>
            );
          })}
        </section>
      </div>
    </CleanLayout>
  );
};

export default BlogAuthor;

export const authorQuery = graphql`
  query getAuthor($skip: Int!, $limit: Int!, $author: String!) {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "it" }, author: { slug: { eq: $author } } }
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        slug
        category {
          name
          slug
        }
        title
        image {
          gatsbyImageData
        }
        author {
          name
          tagline
        }
        subtitle {
          subtitle
        }
      }
    }
    allContentfulAuthor(
      filter: { node_locale: { eq: "it" }, slug: { eq: $author } }
    ) {
      nodes {
        bio {
          bio
        }
        email
        instagram
        linkedin
        name
        picture {
          gatsbyImageData
        }
        slug
        username
        tagline
      }
    }
  }
`;
